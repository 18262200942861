var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c("CSTabCard", { attrs: { cardList: _vm.cardList } }),
      _c(
        "div",
        { staticClass: "result-panel" },
        [
          _c("CSTable", {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "table-header-panel" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" 近10次企业后台登录记录 "),
                        _c(
                          "div",
                          {
                            staticClass: "allow-click",
                            staticStyle: { "margin-left": "20px" },
                            on: {
                              click: function ($event) {
                                return _vm.seeMore()
                              },
                            },
                          },
                          [_vm._v("查看更多")]
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "thead",
                fn: function () {
                  return [
                    _c("tr", [
                      _c("th", [_vm._v("企业后台登录时间")]),
                      _c("th", [_vm._v("登录人姓名")]),
                      _c("th", [_vm._v("性别")]),
                      _c("th", [_vm._v("手机号")]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "tbody",
                fn: function () {
                  return _vm._l(_vm.companyList, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(item.createTime))]),
                      _c("td", [_vm._v(_vm._s(item.name))]),
                      _c("td", [_vm._v(_vm._s(_vm.genderObj[item.gender]))]),
                      _c("td", [_vm._v(_vm._s(item.phone))]),
                    ])
                  })
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }