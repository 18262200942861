<template>
    <div class="home-page">
        <CSTabCard :cardList = "cardList"></CSTabCard>
        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel"
                    >
                        <div class="title">
                            近10次企业后台登录记录
                            <div class="allow-click" @click="seeMore()" style="margin-left: 20px">查看更多</div>
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>企业后台登录时间</th>
                        <th>登录人姓名</th>
                        <th>性别</th>
                        <th>手机号</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(item,index) in companyList" :key="index" >
                        <td>{{item.createTime}}</td>
                        <td>{{item.name}}</td>
                        <td>{{ genderObj[item.gender]}}</td>
                        <td>{{item.phone}}</td>
                    </tr>
                </template>
            </CSTable>
        </div>
    </div>
</template>

<script>
    import {queryCompanyLoginLogsUrl} from "@/requestUrl";
    import CSTable from "@/components/common/CSTable";
    import CSTabCard from '@/components/common/CSTabCard';
    export  default {
        name:'homePage',
        data(){
            return{
                companyList:[],
                genderObj : {
                    1:'男',
                    2:'女',
                    0:'未知'
                },
                cardList:[
                    {
                        url:'companyStaff',
                        name:'公司员工',

                        icon1:'icon-menugongsiyuangongdachicun',
                        icon2:'icon-menugongsiyuangongdachicun-copy'
                    },
                    {
                        url:'employeeAccess',
                        name:'员工通行',
                        icon1:'icon-menuyuangongtonghangdachicun',
                        icon2:'icon-menuyuangongtonghangdachicun-copy'
                    },
                    {
                        url:'loginRecord',
                        name:'登录记录',
                        icon1:'icon-menudenglujiludachicun',
                        icon2:'icon-menudenglujiludachicun-copy',
                    }
                ],
            }
        },
        created() {
            this.searchData()
         },
        components:{
            CSTable,
            CSTabCard
        },
        methods:{
            seeMore(){
                this.$router.push({
                    name:'loginRecord'
                })
            },

            searchData(){
                this.$fly.post(queryCompanyLoginLogsUrl,{
                    "userId":  this.$vc.getCurrentStaffInfo().id //企业id
                }).then(res => {
                    if(res.code != 0){
                        return
                    }

                    this.companyList = res.data;
                })
            },
        }
    }
</script>

<style  scoped>
.title{
    position:relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.title::after{
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    background: rgba(0, 182, 148, 1);
    opacity: 1;
    left: 0;
    top:2px;
}
</style>